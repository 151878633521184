import React, { useState } from 'react'

import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import './register.css'
import Footer from '../components/footer'
import Header from '../components/header'


const Impressum = (props) => {

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    employee_count: "",
    registration_page: "smart-defend.de",
    email_address: "",
  });

  const [submit, setSubmit] = useState(false);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    registerUser(formData);
    setSubmit(!submit)
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const registerUser = (data) => {
    return fetch('https://hc.nsurio.com/api/v1/register/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'b7061546-75e8-444b-a2c4-f19655d07eb8',
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            console.log(response);
            return response;
           
          } else {
           console.log('Registration error');
          }
    }).catch(err => err);
  }

  return (
    <div className="register-container">
      <Helmet>
        <title>Cyber Safe & Sicher</title>
        <meta property="og:title" content="Impressum - Cyber Safe & Sicher" />
      </Helmet>

      <Header/>

      <div className="impressum-banner">
          <h1>Impressum</h1>
          <br/>
          <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p>Nsurio GmbH<br />
          Stargarder Str. 21<br />
          10437 Berlin
          </p>

          <p>Handelsregister: HRB 254732 B<br />
          Registergericht: Amtsgericht Berlin-Charlottenburg</p>
          <br/>
          <p><strong>Vertreten durch:</strong><br />
          Eric Draijer</p>
          <br/>
          <h2>Kontakt</h2>
          <p>Telefon: +4917612759165<br />
          E-Mail: info@nsurion.com</p>
          <br/>
          <h2>Umsatzsteuer-ID</h2>
          <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
          DE362616001</p>
          <br/>
          <h2>EU-Streitschlichtung</h2>
          <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
          <br/>
          <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

      </div>

      <Footer/>
      
    </div>
  )
}

export default Impressum
