import React, {useState} from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import ActiveUsers from '../components/active-users'
import Footer from '../components/footer'

import './home.css'

const Home = (props) => {

  const [showMobileMenu, setMobileMenu] = useState(false);
  const toggleMobileMenu = () => setMobileMenu(value => {
    return !value;
  });

  return (
    <div className="home-container">
      <Helmet>
        <title>Smart Defend</title>
        <meta property="og:title" content="Smart Defend" />
      </Helmet>

      <section className="home-hero">
        <div className="home-menu">
          <div id="mobile-menu" className="home-mobile-navigation">
            <img
              alt="Smart Defend Logo"
              src="/smart-defend-high-resolution-logo-black-transparent-200h.png"
              className="home-logo4"
            />
            <div className="home-links">
              <span className="Link">
                <Link to="/impressum">Impressum</Link>
              </span>
            </div>
            
            <div data-thq="thq-navbar-nav" className="home-desktop-menu">
              <nav className="home-links"></nav>
              <div className="home-buttons">
                <Link to="/register">
                  <button className="home-login button">Anmelden</button>
                </Link>
                <Link to="/register" className="home-right">
                  <button className="home-register button">Registrieren</button>
                </Link>
              </div>

            </div>

            <div id="close-mobile-menu" className="home-close-mobile-menu">
              <svg viewBox="0 0 804.5714285714286 1024" className="home-icon">
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>
          <div className="home-desktop-navigation">
            <nav className="home-centered">
              <div className="home-left">
                <img
                  alt="Smart Defend Logo"
                  src="/smart-defend-high-resolution-logo-black-transparent-200h.png"
                  className="home-logo4"
                />
                <div className="home-links1">
                </div>
              </div>
              <div className="home-right">
                <span className="home-sign-in Link">
                  <Link to="/register">Anmelden</Link>
                </span>
                <div className="home-get-started">
                  <span className="home-text06">
                  <Link to="/register">Jetzt loslegen</Link>
                  </span>
                </div>
                <div id="open-mobile-menu" className="home-burger-menu" onClick={toggleMobileMenu}>
                  <img
                    alt="Open Mobile Menu"
                    src="/pastedimage-yxbd.svg"
                    className="home-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div>
            <div className="home-container2">
              <Script
                html={`<script>
/*
Mobile menu - Code Embed
*/

// Mobile menu
if (typeof mobileMenu === "undefined") {
 mobileMenu = document.querySelector("#mobile-menu")

// Buttons
const closeButton = document.querySelector("#close-mobile-menu")
const openButton = document.querySelector("#open-mobile-menu")

// On openButton click, set the mobileMenu position left to -100vw
openButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(0%)"
})

// On closeButton click, set the mobileMenu position to 0vw
closeButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(100%)"
})
                }
</script>`}
              ></Script>
            </div>
          </div>
        </div>
        <header className="home-header">
          <h1 className="home-text07">Cybersicherheit leicht gemacht</h1>
          <p className="home-text08">
            Mit nur wenigen Klicks können Benutzer aller Qualifikationsstufen schnell und einfach
            auf branchenführenden Schutz zugreifen
          </p>
          <div className="home-get-started1">
            <span className="home-text09">
              <Link to="/register">Jetzt loslegen</Link>
            </span>
          </div>
        </header>
        <div className="home-dashboard-preview">
          <div className="home-outline">
            <img
              alt="pastedImage"
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/f1e2eda1-7fcc-4dd9-b943-e026a3c84c72/f0dfea35-9037-4dcf-9838-99ed6d4f1145?org_if_sml=1&force_format=original"
              loading="lazy"
              className="home-image"
            />
          </div>
        </div>
      </section>


      <section className="home-features">
        <div className="home-title">
          <span className="home-text10">
            Schützen Sie Ihr Unternehmen mit einem maßgeschneiderten und erschwinglichen Schutz
          </span>
          <span className="home-text11">
            Cyberangriffe können für kleine und mittlere Unternehmen verheerend sein. 
            Sind Sie bereit?
          </span>
        </div>
        <div className="home-cards">
          <div className="home-container3">
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-fii6m-200h.png"
                className="home-icon2"
              />
              <span className="home-text12">Überwachung in Echtzeit</span>
              <span className="home-text13">
                Identifizieren Sie Ihr Cyber-Risiko und überwachen Sie automatisch Schwachstellen
              </span>
            </div>
            <div className="home-publish card">
              <img
                alt="pastedImage"
                src="/pastedimage-mimg-200h.png"
                className="home-icon3"
              />
              <span className="home-text14">Bildung &amp; Bewusstheit</span>
              <span className="home-text15">
                Schulen und lehren Sie Ihre Mitarbeiter mit unserem Cyber Sensibilisierungstool.
                Führen Sie Phishing-Sicherheitstests durch.
              </span>
            </div>
          </div>
          <div className="home-container4">
            <div className="card home-analyze">
              <img
                alt="pastedImage"
                src="/pastedimage-l6p-200h.png"
                className="home-icon4"
              />
              <span className="home-text16">Handlungsorientierte Einblicke</span>
              <span className="home-text17">
                <span className="home-text18">
                 Vollständig automatisiert, für die Verbesserung Ihrer Cybersicherheitsstandards
                 und die Identifizierung von &apos;einfachen Korrekturen’.
                </span>
                <br></br>
                <br></br>
              </span>
            </div>
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-vyi5-200h.png"
                className="home-icon5"
              />
              <span className="home-text21">360° Cyber-Schutz</span>
              <span className="home-text22">
                Unser Domainüberwachungsservice schützt Sie vor Domain Typo-Squatting,
                E-Mail-Phishing und Website-Spoofing.
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="home-quote-container">
        <div className="home-quote">
          <span className="home-message">
            “Smart Defend ist anders als jedes andere Cyber-Risiko-Produkt auf dem heutigen Markt.
             In einer Welt, in der Cyber-Risiken bedrohlich sind, und sich rasant weiterentwickeln,
             bietet Smart Defend ein einfaches, schön gestaltetes und leicht verständliches Produkt,
             das mir hilft diese Cyber-Risiken zu verstehen und zu mindern.”
          </span>
          <div className="home-author">
            <img alt="image" src="/tool/emu-200w.png" className="home-avatar" />
            <span className="home-quote1">
              <span className="home-text23">Emanuel Hoch</span>
              <span>, Mitbegründer &amp; CPO-CTO bei Compado</span>
            </span>
          </div>
        </div>
      </section>

      <section className="home-statistics">
        <div className="home-container5">
          <ActiveUsers
            Caption="häufiger von Cyberkriminellen angegriffen als große Unternehmen"
            Statistic="3X"
          ></ActiveUsers>
          <ActiveUsers
            Caption="der KMU waren in den letzten 12 Monaten Opfer mindestens eines Cyber-Angriffs "
            Statistic="83%"
          ></ActiveUsers>
          <ActiveUsers
            Caption="Durchschnittliche Kosten für kleine und mittlere Unternehmen im Falle einer Data Breach"
            Statistic="€3M"
          ></ActiveUsers>
        </div>
      </section>

      <section className="home-banners">
        <div className="home-banner-manage">
          <div className="home-container6">
            <div className="home-left1">
              <span className="sub-title">
                Eine preisgekrönte All-in-One-Lösung
              </span>
              <span className="home-text26 title">
                Cybersecurity, die Sie schützt. So können Sie sich auf Ihr Geschäft konzentrieren.
              </span>
              <span className="home-text27">
                <span>
                  {' '}
                  Wir übernehmen die Kontrolle über Ihre Cybersicherheit,
                  damit Sie die Kontrolle über Ihr Unternehmen behalten können.
                  Wir machen die ganze Arbeit, damit Sie sich nicht ...
                </span>
                <br></br>
              </span>
              <div className="home-get-started2 template-button">
                <span className="home-text30">
                  <Link to="/register">
                    Erfahren Sie mehr
                  </Link>
                </span>
              </div>
            </div>
            <div className="home-image-container">
              <img
                alt="pastedImage"
                src="https://images.unsplash.com/photo-1624969862644-791f3dc98927?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE2fHxjeWJlciUyMHxlbnwwfHx8fDE3MDE5ODE5NDZ8MA&amp;ixlib=rb-4.0.3&amp;w=1200"
                className="home-cards-image"
              />
            </div>
          </div>
        </div>
      </section>
  
      <section className="home-action-bar">
        <div className="home-action">
          <div className="home-heading">
            <span className="home-text31 title">
              Verwandeln Sie Ihre Sicherheit in einen Wegbereiter ihres Unternehmens!
            </span>
            <span className="home-text32">
              Jede Branche hat andere Ziele und Herausforderungen. 
              Deshalb haben wir Smart Defend so konzipiert, dass es auf Ihre Arbeitsweise und Ihre Werte eingeht.
            </span>
            <div className="home-get-started3 template-button">
              <span className="home-text33">
                <Link to="/register">
                  <span>Kostenloser Cyber Gesundheits Check</span>
                </Link>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-images"></div>
        </div>
      </section>
      
      <Footer/>

    </div>
  )
}

export default Home
