import React, {useState} from 'react'

import '../views/home.css'
import { Link } from 'react-router-dom'

const Header = (props) => {

  const [showMobileMenu, setMobileMenu] = useState(false);
  const toggleMobileMenu = () => setMobileMenu(value => {
    return !value;
  });

  return (
    <section className="home-hero-header">
      <div className="home-menu">
        <div id="mobile-menu" className="home-mobile-navigation">
          <img
            alt="Smart Defend Logo"
            src="/smart-defend-high-resolution-logo-black-transparent-200h.png"
            className="home-logo4"
          />
          <div className="home-links">
            <span className="Link">
              <Link to="/impressum">Impressum</Link>
            </span>
          </div>

          <div data-thq="thq-navbar-nav" className="home-desktop-menu">
              <nav className="home-links"></nav>
              <div className="home-buttons">
                <Link to="/register">
                  <button className="home-login button">Anmelden</button>
                </Link>
                <Link to="/register">
                  <button className="home-register button">Registrieren</button>
                </Link>
              </div>
          </div>

          <div id="close-mobile-menu" className="home-close-mobile-menu">
            <svg viewBox="0 0 804.5714285714286 1024" className="home-icon">
              <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
            </svg>
          </div>
        </div>
        <div className="home-desktop-navigation">
          <nav className="home-centered">
            <div className="home-left">
              <Link to="/">
                <img
                  alt="Smart Defend Logo"
                  src="/smart-defend-high-resolution-logo-black-transparent-200h.png"
                  className="home-logo4"
                />
              </Link>
              <div className="home-links1">
              </div>
            </div>
            <div className="home-right">
              <span className="home-sign-in Link">
                <Link to="/register">Anmelden</Link>
              </span>
              <div className="home-get-started">
                <span className="home-text06">
                <Link to="/register">Jetzt loslegen</Link>
                </span>
              </div>
              <div id="open-mobile-menu" className="home-burger-menu">
                <img
                  alt="Open Mobile Menu"
                  src="/pastedimage-yxbd.svg"
                  className="home-mobile-menu-button"
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </section>
  )
}

export default Header
