import React from 'react'
import { Link } from 'react-router-dom'


import '../views/home.css'

const Footer = (props) => {
  return (
    <footer className="home-footer">
        <div className="home-top">
        <div className="home-right1">
            <div className="home-category">
            <span className="home-text39">Unternehmen</span>
                <div className="home-links2">
                <span className="home-text40">
                  <Link to="/impressum">Impressum</Link>
                </span>

                <span className="home-text40">
                  <Link to="/privacy">Datenschutzerklärung</Link>
                </span>

                </div>
            </div>
        </div>
        </div>
        <div className="home-bottom">
            <img
                alt="pastedImage"
            src="/smart-defend-high-resolution-logo-black-transparent-200h.png"
            className="home-branding"
        />
        <span className="home-text44">Copyright © Active - 2023</span>
        </div>
    </footer>
  )
}

export default Footer
